.blocks.alignment-input {
  border-radius: 6px;
  background-color: #e1e1e1;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 4px;
  overflow: hidden;
  user-select: none;
}

.blocks.alignment-input .row {
  border-radius: 6px;
  background-color: #e1e1e1;
  max-width: 150px;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.blocks.alignment-input .option {
  flex: 1;
  text-align: center;
  line-height: 19px;
  color: gray;
  font-size: 10px;
  cursor: pointer;
  border: 1px solid #e1e1e1;
}

.blocks.alignment-input .option.active {
  background-color: white;
  border: 1px solid #d1cfcf;
  cursor: default;
  border-radius: 4px;
}


.maker-add-button {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #e1e1e1;
  border: 1px solid #e1e1e1;
  line-height: 18px;
  font-size: 13px;
  text-align: center;
  transform: translate(-9.5px, 0px);
  box-shadow: 0px 0px 4px #cfcfcf;
  opacity: .6;
  color: gray;
  cursor: pointer;

}

.maker-add-button:hover {
  border: 1px solid #d1cfcf;
  opacity: 1;
}
.contexify {
  --contexify-menu-bgColor: rgba(40,40,40,.98);
  --contexify-separator-color: #4c4c4c;
  --contexify-item-color: #fff;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: #3498db;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
}

.blocks.input-group {
  height: 28px;
  border-radius: 6px;
  background-color: #e1e1e1;
  max-width: 150px;
  flex: 1;
  border: 1px solid #e1e1e1;
  display: flex;
  overflow: hidden;
}

.blocks.input-group:hover {
  border: 1px solid #d1cfcf;
}

.blocks.input-group:focus-within {
  border: 1px solid #47a1f3;
}

.blocks.input-group input {
  border: none;
  outline: none;
  background-color: inherit;
  font-size:10px;
  height: 26px;
  line-height: 26px;
  padding:0px 4px;
  flex: 1;
  width: 30px;

}

.blocks.input-group input:focus {
  border: none;
  outline: none;
}

.blocks.input-group .label {
  line-height: 28px;
  color: gray;
  font-size: 10px;
  padding: 0px 4px;
  flex-shrink: 0;
}


.blocks.input-group .dropdown {
  min-width: 20px;
  text-align: center;
}

.blocks.input-group .dropdown:hover {
  cursor: pointer;
  background-color: #d1cfcf;
}
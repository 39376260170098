@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body,
#root {
  margin: 0px;
  padding: 0px;
  height: 100%;
  background-color: #f5f5f5;
  cursor: default;
}

/** Temp Hack to Fix layout!! **/
body {
  margin-top: -27px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #333333;
}

::-webkit-scrollbar-thumb {
  background: #ffffff88;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff88;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.visible-850 {
  display: none;
}

.medium-popup-image {
  width: 472px
}

.large-popup-image {
  width: 740px
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 600px) { 
  .max-width-auto {
    width: auto !important;
  }
}

@media screen and (max-width: 850px){ 
  .hidden-850 {
    display: none;
  }
  .visible-850 {
    display: block;
  }
}

@media screen and (max-width: 576px){ 
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: block;
  }
}

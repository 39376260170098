.blocks.radio-group {
  height: 28px;
  border-radius: 6px;
  background-color: #e1e1e1;
  max-width: 150px;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.blocks.radio-group .option {
  flex: 1;
  text-align: center;
  line-height: 28px;
  color: gray;
  font-size: 10px;
  cursor: pointer;
}

.blocks.radio-group .option.active {
  background-color: white;
  border: 1px solid #d1cfcf;
  cursor: default;
  border-radius: 4px;
}

.ui-block-tree-view .placeholder {
  background-color: #1967d2;
  height: 2px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%);
}

.ui-block-tree-view .draggingSource {
  opacity: 0.3;
}

.ui-block-tree-view .placeholderContainer {
  position: relative;
}

.ui-block-tree-view .dropTarget {
  background-color: #e8f0fe;
}

.ui-block-tree-view .node {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  height: 24px;
  padding-inline-end: 8px;
  border-bottom: solid 1px #d1cfcf;
  font-size: 12px;
}

.ui-block-tree-view .node.selected {
  background-color: #e1e1e1;
}

.ui-block-tree-view .node .expandIconWrapper {
  align-items: center;
  /* font-size: 0; */
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}

.ui-block-tree-view .node .expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.ui-block-tree-view .node .labelGridItem {
  padding-inline-start: 8px;
}
html {
  scrollbar-color: #24AAE1 #fff;
  scrollbar-width: thin;
}

@font-face {
  font-family: 'WorkSans';
  font-weight: 400;
  src: url(./assets/fonts/WorkSans-Regular.ttf);
}

@font-face {
  font-family: 'WorkSans';
  font-weight: 500;
  src: url(./assets/fonts/WorkSans-Medium.ttf);
}

@font-face {
  font-family: 'WorkSans';
  font-weight: 600;
  src: url(./assets/fonts/WorkSans-SemiBold.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 400;
  src: url(./assets/fonts/Chivo-Regular.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 500;
  src: url(./assets/fonts/Chivo-Medium.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 700;
  src: url(./assets/fonts/Chivo-Bold.ttf);
}

@font-face {
  font-family: 'Chivo';
  font-weight: 800;
  src: url(./assets/fonts/Chivo-ExtraBold.ttf);
}

@media screen and (max-width: 576px){ 
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: block;
  }
}

.easeload {
  opacity: 0;
  -webkit-transition: all .5s ease; 
  -moz-transition: all .5s ease; 
  -ms-transition: all .5s ease; 
  -o-transition: all .5s ease; 
  transform: scale(.5);
}

.react-tooltip {
  font-size: 10px !important;
  padding: 4px 8px !important;
}